import {service} from './service'

import {config} from './config'

const {default_headers} = config

const request = (option) => {
    const {url, method, params, data, headersType, responseType, ...config} = option
    return service({
        url: url,
        method,
        params,
        data,
        ...config,
        responseType: responseType,
        headers: {
            'Content-Type': headersType || default_headers
        }
    })
}
export default {
    get: async (option) => {
        const res = await request({method: 'GET', ...option})
        return res.data
    },
}
