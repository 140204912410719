<template>
<div id="siteWrapper" class="clearfix site-wrapper">
<main id="page" class="container" role="main" v-if="homeShow">
    <article class="sections" id="sections" data-page-sections="626ab72ca804175896735497">
        <section  class='page-section full-bleed-section layout-engine-section background-width--full-bleed section-height--custom content-width--wide horizontal-alignment--center vertical-alignment--middle has-background white'>
            <div class="section-border">
              <div class="section-background">
                <div class="section-background-content">
                  <img  :src="getImg(homeInfo.banner.content)" width="1667" height="2500">
                  <div class="section-background-canvas background-fx-canvas"></div>
                </div>
                <div class="section-background-overlay" style="opacity: 0;"></div>
              </div>
            </div>
            <div  class='content-wrapper'>
                <div class="content">
                    <div data-fluid-engine="true">
                        <div class="fluid-engine fe-63891403fa797363fb4eabb4">
                            <div class="fe-block fe-block-638914031750fda654e3a521">
                                <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2" id="block-638914031750fda654e3a521">
                                    <div class="sqs-block-content">
                                        <div class="sqs-html-content">
                                            <h4 style="white-space:pre-wrap;"><span class="sqsrte-text-color--accent">{{lang?homeInfo.home_title1.content:homeInfo.home_title1.contentEn}}</span></h4><h4 style="white-space:pre-wrap;"><span class="sqsrte-text-color--accent">{{lang?homeInfo.home_title2.content:homeInfo.home_title2.contentEn}}</span></h4><h4 style="white-space:pre-wrap;"><span class="sqsrte-text-color--accent">{{lang?homeInfo.home_title3.content:homeInfo.home_title3.contentEn}}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="fe-block fe-block-6389140357ec6ffff2477aa7">
                                <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2" id="block-6389140357ec6ffff2477aa7">
                                    <div class="sqs-block-content">
                                        <div class="sqs-html-content">
                                            <h4 style="text-align:right;white-space:pre-wrap;"><span class="sqsrte-text-color--white">{{lang?homeInfo.home_title4.content:homeInfo.home_title4.contentEn}}</span></h4><h4 style="text-align:right;white-space:pre-wrap;"><span class="sqsrte-text-color--white">{{lang?homeInfo.home_title5.content:homeInfo.home_title5.contentEn}}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
<section
  data-test="page-section"
  data-section-theme=""
  class='page-section  full-bleed-section layout-engine-section background-width--full-bleed section-height--medium content-width--wide horizontal-alignment--center vertical-alignment--middle'
  data-section-id="63f38ac3f7b083322f9ac100"
  data-controller="SectionWrapperController">
  <div class="section-border">
    <div class="section-background"></div>
  </div>
  <div class='content-wrapper'>
    <div class="content">
      <div data-fluid-engine="true">
        <div class="fluid-engine fe-63f38ac3f7b083322f9ac0ff">
            <div class="fe-block fe-block-yui_3_17_2_1_1676905085052_399941">
                <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2" id="block-yui_3_17_2_1_1676905085052_399941">
                    <div class="sqs-block-content">
                        <div class="sqs-html-content">
                            <h4 style="text-align:center;white-space:pre-wrap;">{{lang?homeInfo.home_title6.content:homeInfo.home_title6.contentEn}}</h4><h4 style="text-align:center;white-space:pre-wrap;">{{lang?homeInfo.home_title7.content:homeInfo.home_title7.contentEn}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fe-block fe-block-78117289986a61b52a2a">
                <div class="sqs-block code-block sqs-block-code" data-block-type="23" id="block-78117289986a61b52a2a">
                    <div class="sqs-block-content">
                        <h2>{{lang?productList[0].title:productList[0].titleEn}}</h2>
                    </div>
                </div>
            </div>
            <div class="fe-block fe-block-4c758f06e842de9b758b">
                <div class="sqs-block image-block sqs-block-image sqs-stretched" data-aspect-ratio="55.37385690129998" data-block-type="5" id="block-4c758f06e842de9b758b">
                    <div class="sqs-block-content">
                        <div class="image-block-outer-wrapper
                            layout-caption-below
                            design-layout-fluid
                            image-position-center
                            combination-animation-site-default
                            individual-animation-site-default"
                        data-test="image-block-fluid-outer-wrapper">
                        <div  class="fluid-image-animation-wrapper sqs-image sqs-block-alignment-wrapper"
                            data-animation-role="image">
                            <div class="fluid-image-container sqs-image-content"
                            style="overflow: hidden;-webkit-mask-image: -webkit-radial-gradient(white, black);border-top-left-radius: 25px;border-top-right-radius: 25px;border-bottom-left-radius: 25px;border-bottom-right-radius: 25px;position: relative;width: 100%;height: 100%;">
                                <a class="sqs-block-image-link content-fill"
                                href="/SecondHomePage">
                                    <img :src="getImg(productList[0].imgPath)" width="1392" height="1739" alt="" sizes="100vw" style="display:block;object-fit: cover; object-position: 50% 50%">
                                    <div class="fluidImageOverlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-de25b4c6480edeaa8e45">
            <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2" id="block-de25b4c6480edeaa8e45">
                <div class="sqs-block-content">
                    <div class="sqs-html-content">
                        <p class="" style="white-space:pre-wrap;line-height:1.5em">{{lang?productList[0].summary:productList[0].summaryEn}}</p>
                    </div>
               </div>
            </div>
        </div>
        <div class="fe-block fe-block-yui_3_17_2_1_1678291123118_92391">
            <div class="sqs-block button-block sqs-block-button sqs-stretched" data-block-type="53" id="block-yui_3_17_2_1_1678291123118_92391">
                <div class="sqs-block-content">
                    <div
                        class="sqs-block-button-container sqs-block-button-container--center"
                        data-animation-role="button"
                        data-alignment="center"
                        data-button-size="medium"
                        data-button-type="primary">
                        <a
                            style="font-size: calc((var(--primary-button-font-font-size-value) - 1) * 1.2vw + 1.4rem);"
                            href="/SecondHomePage"
                            class="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element">
                            {{lang?'了解更多':'Learn more'}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-0fbde0e104978e8d9901">
            <div class="sqs-block code-block sqs-block-code" data-block-type="23" id="block-0fbde0e104978e8d9901">
                <div class="sqs-block-content">
                    <h2>{{lang?productList[1].title:productList[1].titleEn}}</h2>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-b955d3584c3699cfec45">
            <div class="sqs-block image-block sqs-block-image sqs-stretched" data-aspect-ratio="55.37385690129998" data-block-type="5" id="block-b955d3584c3699cfec45">
                <div class="sqs-block-content">
                    <div class="image-block-outer-wrapper
                            layout-caption-below
                            design-layout-fluid
                            image-position-center
                            combination-animation-site-default
                            individual-animation-site-default"
                        data-test="image-block-fluid-outer-wrapper">
                        <div class="fluid-image-animation-wrapper sqs-image sqs-block-alignment-wrapper"
                            data-animation-role="image">
                            <div class="fluid-image-container sqs-image-content"
                             style="overflow: hidden;-webkit-mask-image: -webkit-radial-gradient(white, black);border-top-left-radius: 25px;border-top-right-radius: 25px;border-bottom-left-radius: 25px;border-bottom-right-radius: 25px;position: relative;width: 100%;height: 100%;" >
                                <a
                                    class="sqs-block-image-link content-fill"
                                    href="/ThirdHomepage">
                                    <img :src="getImg(productList[1].imgPath)" width="852" height="1066" alt="" sizes="100vw" style="display:block;object-fit: cover; object-position: 50% 50%">
                                    <div class="fluidImageOverlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-d3717d62eaf528ea22e3">
            <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2"  id="block-d3717d62eaf528ea22e3">
                <div class="sqs-block-content">
                    <div class="sqs-html-content">
                        <p class="" style="white-space:pre-wrap;line-height: 1.8em;">{{lang?productList[1].summary:productList[1].summaryEn}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-3c620ec0eec290d2f28d">
            <div class="sqs-block button-block sqs-block-button sqs-stretched" data-block-type="53" id="block-3c620ec0eec290d2f28d">
                <div class="sqs-block-content">
                    <div
                        class="sqs-block-button-container sqs-block-button-container--center"
                        data-animation-role="button"
                        data-alignment="center"
                        data-button-size="medium"
                        data-button-type="primary"
                        >
                        <a
                            style="font-size: calc((var(--primary-button-font-font-size-value) - 1) * 1.2vw + 1.4rem);"
                            href="/ThirdHomepage"
                            class="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element"
                            >
                            {{lang?'了解更多':'Learn more'}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-yui_3_17_2_1_1676905085052_326314">
            <div class="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1676905085052_326314">
                <div class="sqs-block-content">
                    <h2>{{lang?productList[2].title:productList[2].titleEn}}</h2>
                </div>
            </div>
        </div>
        <div class="fe-block fe-block-yui_3_17_2_1_1676905085052_9008">
            <div class="sqs-block image-block sqs-block-image sqs-stretched" data-aspect-ratio="64.72910929070879" data-block-type="5" id="block-yui_3_17_2_1_1676905085052_9008">
                <div class="sqs-block-content">
                    <div
                        class="image-block-outer-wrapper
                            layout-caption-below
                            design-layout-fluid
                            image-position-center
                            combination-animation-site-default
                            individual-animation-site-default"
                            data-test="image-block-fluid-outer-wrapper"
                        >
                        <div class="fluid-image-animation-wrapper sqs-image sqs-block-alignment-wrapper"
                            data-animation-role="image">
                            <div class="fluid-image-container sqs-image-content"
                            style="overflow: hidden;-webkit-mask-image: -webkit-radial-gradient(white, black);border-top-left-radius: 25px;border-top-right-radius: 25px;border-bottom-left-radius: 25px;border-bottom-right-radius: 25px;position: relative;width: 100%;height: 100%;">
                            <a
                                class="sqs-block-image-link content-fill"
                                href="/home" >
                                <img :src="getImg(productList[2].imgPath)" width="917" height="1147" alt="" sizes="100vw" style="display:block;object-fit: cover; object-position: 50% 50%">
                                <div class="fluidImageOverlay"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="fe-block fe-block-yui_3_17_2_1_1676905085052_111571">
        <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2"  id="block-yui_3_17_2_1_1676905085052_111571">
            <div class="sqs-block-content">
                <div class="sqs-html-content">
                    <p class="" style="white-space:pre-wrap;">{{lang?productList[2].summary:productList[2].summaryEn}}</p>
                </div>
           </div>
        </div>
    </div>
    <div class="fe-block fe-block-f65af719b701df0ea933">
        <div class="sqs-block button-block sqs-block-button sqs-stretched" data-block-type="53" id="block-f65af719b701df0ea933">
            <div class="sqs-block-content">
               <div
                    class="sqs-block-button-container sqs-block-button-container--center"
                    data-animation-role="button"
                    data-alignment="center"
                    data-button-size="medium"
                    data-button-type="primary"
                >
                    <a
                        style="font-size: calc((var(--primary-button-font-font-size-value) - 1) * 1.2vw + 1.4rem);"
                        href="/home"
                        class="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element">
                        {{lang?'了解更多':'Learn more'}}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="fe-block fe-block-86b5f2ea6de393b3ba62">
        <div class="sqs-block code-block sqs-block-code" data-block-type="23" id="block-86b5f2ea6de393b3ba62">
            <div class="sqs-block-content">
                <h2>{{lang?productList[3].title:productList[3].titleEn}}</h2>
            </div>
        </div>
    </div>
    <div class="fe-block fe-block-83998649fddebc842cfd">
        <div class="sqs-block image-block sqs-block-image sqs-stretched" data-aspect-ratio="55.37385690129998" data-block-type="5" id="block-83998649fddebc842cfd">
            <div class="sqs-block-content">
                <div
                class="
                    image-block-outer-wrapper
                    layout-caption-below
                    design-layout-fluid
                    image-position-center
                    combination-animation-site-default
                    individual-animation-site-default
                "
                data-test="image-block-fluid-outer-wrapper"
                >
                <div
                    class="fluid-image-animation-wrapper sqs-image sqs-block-alignment-wrapper"
                    data-animation-role="image">
                    <div
                    class="fluid-image-container sqs-image-content"
                    style="overflow: hidden;-webkit-mask-image: -webkit-radial-gradient(white, black);border-top-left-radius: 25px;border-top-right-radius: 25px;border-bottom-left-radius: 25px;border-bottom-right-radius: 25px;position: relative;width: 100%;height: 100%;">
                        <a
                        class="sqs-block-image-link content-fill"
                        href="/FourthHomePage">
                             <img  :src="getImg(productList[3].imgPath)" width="864" height="1080" alt="" sizes="100vw" style="display:block;object-fit: cover; object-position: 50% 50%">
                             <!-- <div class="flf:\vscode\Microsoft VS Code\resources\app\out\vs\code\electron-sandbox\workbench\workbench.htmluidImageOverlay"></div> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fe-block fe-block-7d54b50cbb5c2c968f71">
    <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2"  id="block-7d54b50cbb5c2c968f71">
        <div class="sqs-block-content">
            <div class="sqs-html-content">
                <p class="" style="white-space:pre-wrap;">{{lang?productList[3].summary:productList[3].summaryEn}}</p>
            </div>
        </div>
    </div>
</div>
<div class="fe-block fe-block-4e07c93ffb6f4d056ec4">
    <div class="sqs-block button-block sqs-block-button sqs-stretched" data-block-type="53" id="block-4e07c93ffb6f4d056ec4">
        <div class="sqs-block-content">
            <div
                class="sqs-block-button-container sqs-block-button-container--center"
                data-animation-role="button"
                data-alignment="center"
                data-button-size="medium"
                data-button-type="primary"
                >
                <a
                    style="font-size: calc((var(--primary-button-font-font-size-value) - 1) * 1.2vw + 1.4rem);"
                    href="/FourthHomePage"
                    class="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element" 
                >
                    {{lang?'了解更多':'Learn more'}}
                </a>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
</section>

  
<!-- <section
  data-test="page-section"
  data-section-theme=""
  class='page-section full-bleed-section layout-engine-section background-width--full-bleed section-height--custom content-width--wide horizontal-alignment--center vertical-alignment--middle'
  style="min-height: 10vh;">
    <div class="section-border">
        <div class="section-background"></div>
    </div>
    <div  class='content-wrapper'
    style='padding-top: calc(10vmax / 10); padding-bottom: calc(10vmax / 10);'>
    <div class="content">
      <div data-fluid-engine="true">
        <div class="fluid-engine fe-6389140a10cf245f5c121566">
            <div class="fe-block fe-block-yui_3_17_2_1_1669927849100_45958">
                <div class="sqs-block html-block sqs-block-html" data-blend-mode="NORMAL" data-block-type="2"  id="block-yui_3_17_2_1_1669927849100_45958">
                    <div class="sqs-block-content">
                        <div class="sqs-html-content">
                            <h4 style="text-align:center;white-space:pre-wrap;">{{lang?homeInfo.home_title8.content:homeInfo.home_title8.contentEn}}</h4>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</section>

<section
  data-test="page-section"
  data-section-theme="black"
  class='page-section gallery-section  full-bleed-section background-width--full-bleed  section-height--medium content-width--wide horizontal-alignment--center  vertical-alignment--middle black'
  data-section-id="626ab72ca8041758967354a5"
  data-controller="SectionWrapperController"
  data-animation="none"
  data-json-schema-section>
  <div class="section-border">
    <div class="section-background"></div>
  </div>
  <div class='content-wrapper'>
    <div class="content">
        <div class="gallery gallery-section-wrapper" style="min-height: 100px;">
           <div class="gallery" style="min-height: 100px;">
                <div
                    class="gallery-grid gallery-grid--layout-grid"
                    data-controller="GalleryGrid"
                    
                    data-section-id="626ab72ca8041758967354a5"
                    data-animation="site-default"
                    data-lightbox=""
                    data-width="full-bleed"
                    data-aspect-ratio="standard"
                    data-columns="4"
                    data-gutter="0"
                    data-props='{
                        "aspectRatio": "standard",
                        "scrollAnimation": "site-default",
                        "gutter": 0,
                        "numColumns": 4,
                        "width": "full-bleed",
                        "lightboxEnabled": false
                    }'
                    data-show-captions="false"
                    data-test="gallery-grid-simple"
                >
                <div class="gallery-grid-wrapper" style="grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0vw;
    grid-row-gap: 0vw;
    padding: 0vw 0vw;
    width: auto;">
                        
                        <figure v-for="(item,index) in imgList" :key="index" class="gallery-grid-item" data-show="true" data-loaded="true">
                            <div class="gallery-grid-item-wrapper" data-animation-role="image">
                            <img :src="getImg(item.imgPath)" width="6000" height="4000" alt="" sizes="(max-width:768px)50vw,(max-width:992px)33.333333333333336vw,25vw" style="display:block;object-position:50% 50%;object-fit:cover;width:100%;height:100%">
                            </div>
                        </figure>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section> -->
</article>
</main>

</div>


</template>

<script>
import * as Api from '@/api/api'
export default {
  data(){
    return {
      homeInfo:{},
      homeShow:false,
      productList:[],
      imgList:[],
    }
  },
  computed: {
    lang() {
      return this.$store.getters.lang == '中文'
    }
  },
  mounted(){
    this.getHomeInfo();
  },
  methods:{
      getImg(url){
        return this.$getPath(url)
      },
     async getHomeInfo(){
         this.homeInfo = await Api.getInfo('首页');     
         this.productList = await Api.getList('首页_科技介绍');
         this.imgList = await Api.getList('首页_产品图片');
         this.homeShow = true;
     },
  }
}
</script>
<style>
.disli ul li{
    margin-top: .5em !important;
    margin-bottom: .5em !important;
}
.top-bun, 
.patty, 
.bottom-bun {
height: 1px;
}
.fe-63f3df2a591e2b3b0badead5 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );
display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(10,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63f3df2a591e2b3b0badead5 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63f3df2a591e2b3b0badead5 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(14,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-f94ebe255781c08feda4 {
grid-area: 3/2/6/10;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-f94ebe255781c08feda4 .sqs-block {
justify-content: center;
}

.fe-block-f94ebe255781c08feda4 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-f94ebe255781c08feda4 {
grid-area: 5/2/9/26;
z-index: 5;


}

.fe-block-f94ebe255781c08feda4 .sqs-block {
justify-content: center;
}

.fe-block-f94ebe255781c08feda4 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-yui_3_17_2_1_1677538461374_43422 {
grid-area: 7/2/9/10;
z-index: 6;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1677538461374_43422 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1677538461374_43422 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1677538461374_43422 {
grid-area: 9/8/12/20;
z-index: 6;


}

.fe-block-yui_3_17_2_1_1677538461374_43422 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1677538461374_43422 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-63eecfdb11e61b754acd5870 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(18,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63eecfdb11e61b754acd5870 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63eecfdb11e61b754acd5870 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(14,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-775bae9bf26c35bcd3f7 {
grid-area: 1/2/9/10;
z-index: 0;

@media (max-width: 767px) {

}
}

.fe-block-775bae9bf26c35bcd3f7 .sqs-block {
justify-content: center;
}

.fe-block-775bae9bf26c35bcd3f7 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-775bae9bf26c35bcd3f7 {
grid-area: 2/4/14/13;
z-index: 0;


}

.fe-block-775bae9bf26c35bcd3f7 .sqs-block {
justify-content: center;
}

.fe-block-775bae9bf26c35bcd3f7 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-46bce825489ad36f50e7 {
grid-area: 10/2/12/10;
z-index: 2;

@media (max-width: 767px) {

}
}

.fe-block-46bce825489ad36f50e7 .sqs-block {
justify-content: flex-start;
}

.fe-block-46bce825489ad36f50e7 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-46bce825489ad36f50e7 {
grid-area: 4/15/6/23;
z-index: 2;


}

.fe-block-46bce825489ad36f50e7 .sqs-block {
justify-content: flex-start;
}

.fe-block-46bce825489ad36f50e7 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-82d2724a2f8ff9765725 {
grid-area: 12/2/18/10;
z-index: 3;

@media (max-width: 767px) {

}
}

.fe-block-82d2724a2f8ff9765725 .sqs-block {
justify-content: flex-start;
}

.fe-block-82d2724a2f8ff9765725 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-82d2724a2f8ff9765725 {
grid-area: 7/15/12/25;
z-index: 3;


}

.fe-block-82d2724a2f8ff9765725 .sqs-block {
justify-content: flex-start;
}

.fe-block-82d2724a2f8ff9765725 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}
.fe-block-775bae9bf26c35bcd3f7 .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
--image-block-overlay-color:hsla(var(--lightAccent-hsl), 0.6);
opacity: 0;
}
.fe-63eecfdb11e61b754acd5873 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(40,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63eecfdb11e61b754acd5873 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63eecfdb11e61b754acd5873 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(22,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-b46cafd4c04d36037078 {
grid-area: 1/2/3/10;
z-index: 11;

@media (max-width: 767px) {

}
}

.fe-block-b46cafd4c04d36037078 .sqs-block {
justify-content: flex-start;
}

.fe-block-b46cafd4c04d36037078 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-b46cafd4c04d36037078 {
grid-area: 2/1/5/27;
z-index: 11;


}

.fe-block-b46cafd4c04d36037078 .sqs-block {
justify-content: flex-start;
}

.fe-block-b46cafd4c04d36037078 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-65ac35889b75222a207c {
grid-area: 3/2/7/10;
z-index: 1;

@media (max-width: 767px) {

}
}

.fe-block-65ac35889b75222a207c .sqs-block {
justify-content: flex-start;
}

.fe-block-65ac35889b75222a207c .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-65ac35889b75222a207c {
grid-area: 6/2/11/9;
z-index: 1;


}

.fe-block-65ac35889b75222a207c .sqs-block {
justify-content: flex-start;
}

.fe-block-65ac35889b75222a207c .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-bd0accf6ae9c7e3dcf40 {
grid-area: 8/2/12/10;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-bd0accf6ae9c7e3dcf40 .sqs-block {
justify-content: flex-start;
}

.fe-block-bd0accf6ae9c7e3dcf40 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-bd0accf6ae9c7e3dcf40 {
grid-area: 6/11/11/17;
z-index: 5;


}

.fe-block-bd0accf6ae9c7e3dcf40 .sqs-block {
justify-content: flex-start;
}

.fe-block-bd0accf6ae9c7e3dcf40 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-2e88cebdad3bd9fb5ebf {
grid-area: 13/2/18/10;
z-index: 7;

@media (max-width: 767px) {

}
}

.fe-block-2e88cebdad3bd9fb5ebf .sqs-block {
justify-content: flex-start;
}

.fe-block-2e88cebdad3bd9fb5ebf .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-2e88cebdad3bd9fb5ebf {
grid-area: 6/19/14/26;
z-index: 7;


}

.fe-block-2e88cebdad3bd9fb5ebf .sqs-block {
justify-content: flex-start;
}

.fe-block-2e88cebdad3bd9fb5ebf .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-ee9d69133f3c6620a398 {
grid-area: 19/2/24/10;
z-index: 8;

@media (max-width: 767px) {

}
}

.fe-block-ee9d69133f3c6620a398 .sqs-block {
justify-content: flex-start;
}

.fe-block-ee9d69133f3c6620a398 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-ee9d69133f3c6620a398 {
grid-area: 14/2/21/9;
z-index: 8;


}

.fe-block-ee9d69133f3c6620a398 .sqs-block {
justify-content: flex-start;
}

.fe-block-ee9d69133f3c6620a398 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-1c1560be5355c6b460e1 {
grid-area: 25/2/29/10;
z-index: 9;

@media (max-width: 767px) {

}
}

.fe-block-1c1560be5355c6b460e1 .sqs-block {
justify-content: flex-start;
}

.fe-block-1c1560be5355c6b460e1 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-1c1560be5355c6b460e1 {
grid-area: 14/11/20/17;
z-index: 9;


}

.fe-block-1c1560be5355c6b460e1 .sqs-block {
justify-content: flex-start;
}

.fe-block-1c1560be5355c6b460e1 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-53ac543baa114f5e28f7 {
grid-area: 30/2/34/10;
z-index: 10;

@media (max-width: 767px) {

}
}

.fe-block-53ac543baa114f5e28f7 .sqs-block {
justify-content: flex-start;
}

.fe-block-53ac543baa114f5e28f7 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-53ac543baa114f5e28f7 {
grid-area: 14/19/20/26;
z-index: 10;


}

.fe-block-53ac543baa114f5e28f7 .sqs-block {
justify-content: flex-start;
}

.fe-block-53ac543baa114f5e28f7 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-370b4e710224fab31200 {
grid-area: 35/2/37/10;
z-index: 13;

@media (max-width: 767px) {

}
}

.fe-block-370b4e710224fab31200 .sqs-block {
justify-content: center;
}

.fe-block-370b4e710224fab31200 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-370b4e710224fab31200 {
grid-area: 21/12/23/16;
z-index: 13;


}

.fe-block-370b4e710224fab31200 .sqs-block {
justify-content: center;
}

.fe-block-370b4e710224fab31200 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-16417cfe229c01b64e91 {
grid-area: 37/2/39/10;
z-index: 14;

@media (max-width: 767px) {

}
}

.fe-block-16417cfe229c01b64e91 .sqs-block {
justify-content: center;
}

.fe-block-16417cfe229c01b64e91 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-16417cfe229c01b64e91 {
grid-area: 21/21/23/24;
z-index: 14;


}

.fe-block-16417cfe229c01b64e91 .sqs-block {
justify-content: center;
}

.fe-block-16417cfe229c01b64e91 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-46053feb6a8b0d9d1583 {
grid-area: 39/2/41/10;
z-index: 15;

@media (max-width: 767px) {

}
}

.fe-block-46053feb6a8b0d9d1583 .sqs-block {
justify-content: center;
}

.fe-block-46053feb6a8b0d9d1583 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-46053feb6a8b0d9d1583 {
grid-area: 21/4/23/7;
z-index: 15;


}

.fe-block-46053feb6a8b0d9d1583 .sqs-block {
justify-content: center;
}

.fe-block-46053feb6a8b0d9d1583 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-63eecfdb11e61b754acd5876 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(43,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63eecfdb11e61b754acd5876 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63eecfdb11e61b754acd5876 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(37,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-a26e04e7a63de849384a {
grid-area: 1/1/4/11;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-a26e04e7a63de849384a .sqs-block {
justify-content: center;
}

.fe-block-a26e04e7a63de849384a .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-a26e04e7a63de849384a {
grid-area: 1/2/9/26;
z-index: 5;


}

.fe-block-a26e04e7a63de849384a .sqs-block {
justify-content: center;
}

.fe-block-a26e04e7a63de849384a .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-ff28390fc61025602886 {
grid-area: 5/2/11/10;
z-index: 0;

@media (max-width: 767px) {

}
}

.fe-block-ff28390fc61025602886 .sqs-block {
justify-content: center;
}

.fe-block-ff28390fc61025602886 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-ff28390fc61025602886 {
grid-area: 10/3/24/13;
z-index: 0;


}

.fe-block-ff28390fc61025602886 .sqs-block {
justify-content: center;
}

.fe-block-ff28390fc61025602886 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-a677057b92c272f275c8 {
grid-area: 11/2/25/10;
z-index: 1;

@media (max-width: 767px) {

}
}

.fe-block-a677057b92c272f275c8 .sqs-block {
justify-content: flex-start;
}

.fe-block-a677057b92c272f275c8 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-a677057b92c272f275c8 {
grid-area: 10/15/24/25;
z-index: 1;


}

.fe-block-a677057b92c272f275c8 .sqs-block {
justify-content: flex-start;
}

.fe-block-a677057b92c272f275c8 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-yui_3_17_2_1_1678308597775_22203 {
grid-area: 25/2/27/10;
z-index: 6;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1678308597775_22203 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1678308597775_22203 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1678308597775_22203 {
grid-area: 24/18/26/22;
z-index: 6;


}

.fe-block-yui_3_17_2_1_1678308597775_22203 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1678308597775_22203 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-d7c1a76510c27b8c9ebc {
grid-area: 29/2/35/10;
z-index: 2;

@media (max-width: 767px) {

}
}

.fe-block-d7c1a76510c27b8c9ebc .sqs-block {
justify-content: center;
}

.fe-block-d7c1a76510c27b8c9ebc .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-d7c1a76510c27b8c9ebc {
grid-area: 28/15/38/25;
z-index: 2;


}

.fe-block-d7c1a76510c27b8c9ebc .sqs-block {
justify-content: center;
}

.fe-block-d7c1a76510c27b8c9ebc .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-fb7a6a516b9db885bcec {
grid-area: 35/2/37/10;
z-index: 3;

@media (max-width: 767px) {

}
}

.fe-block-fb7a6a516b9db885bcec .sqs-block {
justify-content: flex-start;
}

.fe-block-fb7a6a516b9db885bcec .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-fb7a6a516b9db885bcec {
grid-area: 28/3/30/13;
z-index: 3;


}

.fe-block-fb7a6a516b9db885bcec .sqs-block {
justify-content: flex-start;
}

.fe-block-fb7a6a516b9db885bcec .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-e1755f7d3fbe0435126a {
grid-area: 37/2/44/10;
z-index: 4;

@media (max-width: 767px) {

}
}

.fe-block-e1755f7d3fbe0435126a .sqs-block {
justify-content: flex-start;
}

.fe-block-e1755f7d3fbe0435126a .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-e1755f7d3fbe0435126a {
grid-area: 30/3/37/13;
z-index: 4;


}

.fe-block-e1755f7d3fbe0435126a .sqs-block {
justify-content: flex-start;
}

.fe-block-e1755f7d3fbe0435126a .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}


.fe-block-ff28390fc61025602886 .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}
.fe-block-d7c1a76510c27b8c9ebc .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
}
.fe-63891fe3412fdb85ddc7ba2f {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(14,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63891fe3412fdb85ddc7ba2f {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63891fe3412fdb85ddc7ba2f {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(5,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-63891fe323333132d679f9a6 {
grid-area: 1/2/5/10;
z-index: 1;

@media (max-width: 767px) {

}
}

.fe-block-63891fe323333132d679f9a6 .sqs-block {
justify-content: flex-start;
}

.fe-block-63891fe323333132d679f9a6 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-63891fe323333132d679f9a6 {
grid-area: 1/8/5/17;
z-index: 1;


}

.fe-block-63891fe323333132d679f9a6 .sqs-block {
justify-content: flex-start;
}

.fe-block-63891fe323333132d679f9a6 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-63891fe3068138396b7f74df {
grid-area: 3/8/5/10;
z-index: 3;

@media (max-width: 767px) {

}
}

.fe-block-63891fe3068138396b7f74df .sqs-block {
justify-content: center;
}

.fe-block-63891fe3068138396b7f74df .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-63891fe3068138396b7f74df {
grid-area: 1/24/3/26;
z-index: 3;


}

.fe-block-63891fe3068138396b7f74df .sqs-block {
justify-content: center;
}

.fe-block-63891fe3068138396b7f74df .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-yui_3_17_2_1_1710966149877_4378 {
grid-area: 5/2/7/10;
z-index: 7;

@media (max-width: 767px) {



}
}

.fe-block-yui_3_17_2_1_1710966149877_4378 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1710966149877_4378 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1710966149877_4378 {
grid-area: 4/2/6/16;
z-index: 7;




}

.fe-block-yui_3_17_2_1_1710966149877_4378 .sqs-block {
justify-content: flex-end;
}

.fe-block-yui_3_17_2_1_1710966149877_4378 .sqs-block-alignment-wrapper {
align-items: flex-end;
}
}

.fe-block-63891fe353e0ac3e37336096 {
grid-area: 7/2/11/10;
z-index: 0;

@media (max-width: 767px) {

}
}

.fe-block-63891fe353e0ac3e37336096 .sqs-block {
justify-content: flex-start;
}

.fe-block-63891fe353e0ac3e37336096 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-63891fe353e0ac3e37336096 {
grid-area: 1/2/6/8;
z-index: 0;


}

.fe-block-63891fe353e0ac3e37336096 .sqs-block {
justify-content: flex-start;
}

.fe-block-63891fe353e0ac3e37336096 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-yui_3_17_2_1_1674502553730_3425 {
grid-area: 11/2/13/6;
z-index: 4;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1674502553730_3425 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1674502553730_3425 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1674502553730_3425 {
grid-area: 4/17/6/20;
z-index: 4;


}

.fe-block-yui_3_17_2_1_1674502553730_3425 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1674502553730_3425 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-yui_3_17_2_1_1674502553730_6629 {
grid-area: 11/6/13/10;
z-index: 6;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1674502553730_6629 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1674502553730_6629 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1674502553730_6629 {
grid-area: 4/23/6/26;
z-index: 6;


}

.fe-block-yui_3_17_2_1_1674502553730_6629 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1674502553730_6629 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-c4d756708f819deb9d15 {
grid-area: 13/2/15/10;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-c4d756708f819deb9d15 .sqs-block {
justify-content: center;
}

.fe-block-c4d756708f819deb9d15 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-c4d756708f819deb9d15 {
grid-area: 4/20/6/23;
z-index: 5;


}

.fe-block-c4d756708f819deb9d15 .sqs-block {
justify-content: center;
}

.fe-block-c4d756708f819deb9d15 .sqs-block-alignment-wrapper {
align-items: center;
}
}

#block-63891fe3068138396b7f74df .social-icons-style-border .sqs-svg-icon--wrapper {     
box-shadow: 0 0 0 2px inset;
border: none; 
} 


.fe-63891403fa797363fb4eabb4 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(13,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63891403fa797363fb4eabb4 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63891403fa797363fb4eabb4 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(13,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-638914031750fda654e3a521 {
grid-area: 2/2/8/10;
z-index: 2;

@media (max-width: 767px) {

}
}

.fe-block-638914031750fda654e3a521 .sqs-block {
justify-content: flex-start;
}

.fe-block-638914031750fda654e3a521 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-638914031750fda654e3a521 {
grid-area: 1/2/10/26;
z-index: 2;


}

.fe-block-638914031750fda654e3a521 .sqs-block {
justify-content: center;
}

.fe-block-638914031750fda654e3a521 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-6389140357ec6ffff2477aa7 {
grid-area: 9/2/13/10;
z-index: 1;

@media (max-width: 767px) {

}
}

.fe-block-6389140357ec6ffff2477aa7 .sqs-block {
justify-content: flex-start;
}

.fe-block-6389140357ec6ffff2477aa7 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-6389140357ec6ffff2477aa7 {
grid-area: 7/2/13/26;
z-index: 1;


}

.fe-block-6389140357ec6ffff2477aa7 .sqs-block {
justify-content: center;
}

.fe-block-6389140357ec6ffff2477aa7 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-63f38ac3f7b083322f9ac0ff {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(75,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-63f38ac3f7b083322f9ac0ff {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-63f38ac3f7b083322f9ac0ff {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(56,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-yui_3_17_2_1_1676905085052_399941 {
grid-area: 1/1/6/11;
z-index: 11;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1676905085052_399941 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1676905085052_399941 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1676905085052_399941 {
grid-area: 1/2/9/26;
z-index: 11;


}

.fe-block-yui_3_17_2_1_1676905085052_399941 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1676905085052_399941 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-78117289986a61b52a2a {
grid-area: 8/2/11/10;
z-index: 8;

@media (max-width: 767px) {

}
}

.fe-block-78117289986a61b52a2a .sqs-block {
justify-content: center;
}

.fe-block-78117289986a61b52a2a .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-78117289986a61b52a2a {
grid-area: 11/15/13/25;
z-index: 8;


}

.fe-block-78117289986a61b52a2a .sqs-block {
justify-content: center;
}

.fe-block-78117289986a61b52a2a .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-4c758f06e842de9b758b {
grid-area: 11/2/17/10;
z-index: 2;

@media (max-width: 767px) {

}
}

.fe-block-4c758f06e842de9b758b .sqs-block {
justify-content: center;
}

.fe-block-4c758f06e842de9b758b .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-4c758f06e842de9b758b {
grid-area: 12/3/18/13;
z-index: 2;


}

.fe-block-4c758f06e842de9b758b .sqs-block {
justify-content: center;
}

.fe-block-4c758f06e842de9b758b .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-de25b4c6480edeaa8e45 {
grid-area: 17/2/22/10;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-de25b4c6480edeaa8e45 .sqs-block {
justify-content: flex-start;
}

.fe-block-de25b4c6480edeaa8e45 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-de25b4c6480edeaa8e45 {
grid-area: 13/15/19/25;
z-index: 5;


}

.fe-block-de25b4c6480edeaa8e45 .sqs-block {
justify-content: flex-start;
}

.fe-block-de25b4c6480edeaa8e45 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-yui_3_17_2_1_1678291123118_92391 {
grid-area: 21/2/23/10;
z-index: 12;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1678291123118_92391 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1678291123118_92391 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1678291123118_92391 {
grid-area: 20/18/22/22;
z-index: 12;


}

.fe-block-yui_3_17_2_1_1678291123118_92391 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1678291123118_92391 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-0fbde0e104978e8d9901 {
grid-area: 25/2/28/10;
z-index: 9;

@media (max-width: 767px) {

}
}

.fe-block-0fbde0e104978e8d9901 .sqs-block {
justify-content: center;
}

.fe-block-0fbde0e104978e8d9901 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-0fbde0e104978e8d9901 {
grid-area: 25/3/27/13;
z-index: 9;


}

.fe-block-0fbde0e104978e8d9901 .sqs-block {
justify-content: center;
}

.fe-block-0fbde0e104978e8d9901 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-b955d3584c3699cfec45 {
grid-area: 28/2/34/10;
z-index: 2;

@media (max-width: 767px) {

}
}

.fe-block-b955d3584c3699cfec45 .sqs-block {
justify-content: center;
}

.fe-block-b955d3584c3699cfec45 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-b955d3584c3699cfec45 {
grid-area: 26/15/31/25;
z-index: 2;


}

.fe-block-b955d3584c3699cfec45 .sqs-block {
justify-content: center;
}

.fe-block-b955d3584c3699cfec45 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-d3717d62eaf528ea22e3 {
grid-area: 34/2/39/10;
z-index: 5;

@media (max-width: 767px) {

}
}

.fe-block-d3717d62eaf528ea22e3 .sqs-block {
justify-content: flex-start;
}

.fe-block-d3717d62eaf528ea22e3 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-d3717d62eaf528ea22e3 {
grid-area: 27/3/32/13;
z-index: 5;


}

.fe-block-d3717d62eaf528ea22e3 .sqs-block {
justify-content: flex-start;
}

.fe-block-d3717d62eaf528ea22e3 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-3c620ec0eec290d2f28d {
grid-area: 39/2/41/10;
z-index: 13;

@media (max-width: 767px) {

}
}

.fe-block-3c620ec0eec290d2f28d .sqs-block {
justify-content: center;
}

.fe-block-3c620ec0eec290d2f28d .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-3c620ec0eec290d2f28d {
grid-area: 33/6/35/10;
z-index: 13;


}

.fe-block-3c620ec0eec290d2f28d .sqs-block {
justify-content: center;
}

.fe-block-3c620ec0eec290d2f28d .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-yui_3_17_2_1_1676905085052_326314 {
grid-area: 43/2/46/10;
z-index: 7;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1676905085052_326314 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1676905085052_326314 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1676905085052_326314 {
grid-area: 37/3/39/13;
z-index: 7;


}

.fe-block-yui_3_17_2_1_1676905085052_326314 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1676905085052_326314 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-yui_3_17_2_1_1676905085052_9008 {
grid-area: 46/2/52/10;
z-index: 1;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1676905085052_9008 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1676905085052_9008 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1676905085052_9008 {
grid-area: 43/3/53/13;
z-index: 1;


}

.fe-block-yui_3_17_2_1_1676905085052_9008 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1676905085052_9008 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-yui_3_17_2_1_1676905085052_111571 {
grid-area: 52/2/57/10;
z-index: 4;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1676905085052_111571 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1676905085052_111571 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1676905085052_111571 {
grid-area: 39/3/43/13;
z-index: 4;


}

.fe-block-yui_3_17_2_1_1676905085052_111571 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1676905085052_111571 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-f65af719b701df0ea933 {
grid-area: 57/2/59/10;
z-index: 14;

@media (max-width: 767px) {

}
}

.fe-block-f65af719b701df0ea933 .sqs-block {
justify-content: center;
}

.fe-block-f65af719b701df0ea933 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-f65af719b701df0ea933 {
grid-area: 54/6/56/10;
z-index: 14;


}

.fe-block-f65af719b701df0ea933 .sqs-block {
justify-content: center;
}

.fe-block-f65af719b701df0ea933 .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-86b5f2ea6de393b3ba62 {
grid-area: 61/2/63/10;
z-index: 10;

@media (max-width: 767px) {

}
}

.fe-block-86b5f2ea6de393b3ba62 .sqs-block {
justify-content: center;
}

.fe-block-86b5f2ea6de393b3ba62 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-86b5f2ea6de393b3ba62 {
grid-area: 37/15/39/25;
z-index: 10;


}

.fe-block-86b5f2ea6de393b3ba62 .sqs-block {
justify-content: flex-start;
}

.fe-block-86b5f2ea6de393b3ba62 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-83998649fddebc842cfd {
grid-area: 63/2/69/10;
z-index: 3;

@media (max-width: 767px) {

}
}

.fe-block-83998649fddebc842cfd .sqs-block {
justify-content: center;
}

.fe-block-83998649fddebc842cfd .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-83998649fddebc842cfd {
grid-area: 43/15/53/25;
z-index: 3;


}

.fe-block-83998649fddebc842cfd .sqs-block {
justify-content: center;
}

.fe-block-83998649fddebc842cfd .sqs-block-alignment-wrapper {
align-items: center;
}
}

.fe-block-7d54b50cbb5c2c968f71 {
grid-area: 69/2/74/10;
z-index: 6;

@media (max-width: 767px) {

}
}

.fe-block-7d54b50cbb5c2c968f71 .sqs-block {
justify-content: flex-start;
}

.fe-block-7d54b50cbb5c2c968f71 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-7d54b50cbb5c2c968f71 {
grid-area: 39/15/43/25;
z-index: 6;


}

.fe-block-7d54b50cbb5c2c968f71 .sqs-block {
justify-content: flex-start;
}

.fe-block-7d54b50cbb5c2c968f71 .sqs-block-alignment-wrapper {
align-items: flex-start;
}
}

.fe-block-4e07c93ffb6f4d056ec4 {
grid-area: 74/2/76/10;
z-index: 15;

@media (max-width: 767px) {

}
}

.fe-block-4e07c93ffb6f4d056ec4 .sqs-block {
justify-content: center;
}

.fe-block-4e07c93ffb6f4d056ec4 .sqs-block-alignment-wrapper {
align-items: center;
}

@media (min-width: 768px) {
.fe-block-4e07c93ffb6f4d056ec4 {
grid-area: 54/18/56/22;
z-index: 15;


}

.fe-block-4e07c93ffb6f4d056ec4 .sqs-block {
justify-content: center;
}

.fe-block-4e07c93ffb6f4d056ec4 .sqs-block-alignment-wrapper {
align-items: center;
}
}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}


.fe-block-4c758f06e842de9b758b .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
mix-blend-mode: normal;





opacity: 0;

}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}


.fe-block-b955d3584c3699cfec45 .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
mix-blend-mode: normal;





opacity: 0;

}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}


.fe-block-yui_3_17_2_1_1676905085052_9008 .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
mix-blend-mode: normal;





opacity: 0;

}
.sqs-block-image .sqs-block-content {
height: 100%;
width: 100%;
}


.fe-block-83998649fddebc842cfd .fluidImageOverlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
mix-blend-mode: normal;





opacity: 0;

}      
.fe-6389140a10cf245f5c121566 {
--grid-gutter: calc(var(--sqs-mobile-site-gutter, 6vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (8 - 1)) ) / 8 );

display: grid;
position: relative;
grid-area: 1/1/-1/-1;
grid-template-rows: repeat(4,minmax(24px, auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(8, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
row-gap: 11.0px;
column-gap: 11.0px;
}

@media (min-width: 768px) {
.background-width--inset .fe-6389140a10cf245f5c121566 {
--inset-padding: calc(var(--sqs-site-gutter) * 2);
}

.fe-6389140a10cf245f5c121566 {
--grid-gutter: calc(var(--sqs-site-gutter, 4vw) - 11.0px);
--cell-max-width: calc( ( var(--sqs-site-max-width, 1500px) - (11.0px * (24 - 1)) ) / 24 );
--inset-padding: 0vw;

--row-height-scaling-factor: 0.0215;
--container-width: min(var(--sqs-site-max-width, 1500px), calc(100vw - var(--sqs-site-gutter, 4vw) * 2 - var(--inset-padding) ));

grid-template-rows: repeat(5,minmax(calc(var(--container-width) * var(--row-height-scaling-factor)), auto));
grid-template-columns:
minmax(var(--grid-gutter), 1fr)
repeat(24, minmax(0, var(--cell-max-width)))
minmax(var(--grid-gutter), 1fr);
}
}


.fe-block-yui_3_17_2_1_1669927849100_45958 {
grid-area: 3/2/5/10;
z-index: 3;

@media (max-width: 767px) {

}
}

.fe-block-yui_3_17_2_1_1669927849100_45958 .sqs-block {
justify-content: flex-start;
}

.fe-block-yui_3_17_2_1_1669927849100_45958 .sqs-block-alignment-wrapper {
align-items: flex-start;
}

@media (min-width: 768px) {
.fe-block-yui_3_17_2_1_1669927849100_45958 {
grid-area: 2/2/5/26;
z-index: 3;


}

.fe-block-yui_3_17_2_1_1669927849100_45958 .sqs-block {
justify-content: center;
}

.fe-block-yui_3_17_2_1_1669927849100_45958 .sqs-block-alignment-wrapper {
align-items: center;
}
}
</style>
