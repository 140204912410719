import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {config} from './api/config'
Vue.config.productionTip = false;

Vue.prototype.$getPath = function (path) {
  return config.imgUrl + path;
}

Vue.prototype.$getContent = function (content) {
  if (content) {
		var reg = new RegExp("/prod-api","g");
        return content
            .replace(/<img/g, "<img style='width:100%;'")
            .replace(reg, config.imgUrl + "")
    } else {
        return ''
    }
}

Vue.use({
  lang: localStorage.getItem("lang") || true, // set element-ui default size
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
