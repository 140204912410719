<template>
  <div id="siteWrapper" class="clearfix site-wrapper">
    <header
    data-test="header"
    id="header"
    class="white-bold header theme-col--primary"
    data-controller="Header"
    data-current-styles=""
    data-section-id="header"
    data-header-theme="white-bold"
    data-menu-overlay-theme=""
    data-header-style="theme"
    data-first-focusable-element
    tabindex="-1"
    
>
    <div class="header-announcement-bar-wrapper">
      <div class='header-inner container--fluid
        header-mobile-layout-logo-center-nav-left
        header-layout-nav-left'
        data-test="header-inner"
        >
        <div class="header-display-desktop">
            <div class="header-title-nav-wrapper">
              <div class="header-title"> 
                  <div class="header-title-logo">
                    <a href="/" v-if="logoImg">
                        <img elementtiming="nbf-header-logo-desktop" :src="logoImg" style="display:block" >
                    </a>
                  </div>
              </div>
              <div class="header-nav">
                  <div class="header-nav-wrapper">
                    <nav class="header-nav-list">
                      <div v-for="(item,index) in tabber" :class="['header-nav-item header-nav-item--collection', item.summary == activeName?'header-nav-item--active':'']">
                        <a :href="item.summary"
                          data-animation-role="header-element"
                          aria-current="page">
                          {{lang?item.title:item.titleEn}}
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
            </div>
            <!-- <div class="header-actions header-actions--right" @click="changeLang">
                {{lang?'English':'中文'}}
            </div> -->



            <div class="header-burger menu-overlay-has-visible-non-navigation-items">
              <button class="header-burger-btn burger">
                <span hidden class="js-header-burger-open-title visually-hidden">Open Menu</span>
                <span hidden class="js-header-burger-close-title visually-hidden">Close Menu</span>
                <div class="burger-box">
                  <div class="burger-inner header-menu-icon-doubleLineHamburger">
                    <div class="top-bun"></div>
                    <div class="patty"></div>
                    <div class="bottom-bun"></div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div class="header-display-mobile">   
            <div class="header-burger menu-overlay-has-visible-non-navigation-items">
              <button class="header-burger-btn burger">
                <span hidden class="js-header-burger-open-title visually-hidden">Open Menu</span>
                <span hidden class="js-header-burger-close-title visually-hidden">Close Menu</span>
                <div class="burger-box">
                  <div class="burger-inner header-menu-icon-doubleLineHamburger">
                    <div class="top-bun"></div>
                    <div class="patty"></div>
                    <div class="bottom-bun"></div>
                  </div>
                </div>
              </button>
            </div>
            <div class="header-title-nav-wrapper">
                <div class="header-title">
                  <div class="header-title-logo">
                    <a href="/">
                        <img :src="logoImg"  style="display:block">
                    </a>
                  </div>
                </div>
                <div class="header-nav">
                  <div class="header-nav-wrapper">
                    <nav class="header-nav-list">
                      <div  v-for="(item,index) in tabber" :class="['header-nav-item header-nav-item--collection', item.summary == activeName?'header-nav-item--active':'']">
                        <a :href="item.summary"
                          data-animation-role="header-element"
                          aria-current="page"
                        >
                          {{lang?item.title:item.titleEn}}
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- (Mobile) Menu Navigation -->
    <div class="header-menu header-menu--folder-list"
      data-current-styles=""
      data-section-id="overlay-nav"
      data-show-account-login="true"
      data-test="header-menu">
      <div class="header-menu-bg theme-bg--primary"></div>
      <div class="header-menu-nav">
        <nav class="header-menu-nav-list">
          <div data-folder="root" class="header-menu-nav-folder">
            <div class="header-menu-nav-folder-content">
              <!-- Menu Navigation -->
              <div class="header-menu-nav-wrapper">
                <div v-for="(item,index) in tabber" :class="['container header-menu-nav-item header-menu-nav-item--collection', item.summary == activeName?'header-menu-nav-item--active':'']">
                  <a v-if="item.summary == activeName" :href="item.summary"
                    aria-current="page">
                    <div class="header-menu-nav-item-content">
                      {{lang?item.title:item.titleEn}}
                    </div>
                  </a>
                  <a v-else :href="item.summary">
                    <div class="header-menu-nav-item-content">
                      {{lang?item.title:item.titleEn}}
                    </div>
                  </a>
              </div>
            </div>
          </div>
          </div>
        </nav>
      </div>
    </div>
</header>
    <router-view/>
<footer class="sections" id="footer-sections" v-if="showAll">
<section
  data-test="page-section"
  data-section-theme="dark"
  class='page-section full-bleed-section layout-engine-section background-width--full-bleed section-height--custom content-width--wide horizontal-alignment--center vertical-alignment--middle dark'
  style="min-height: 15vh;">
  <div class="section-border">
    <div class="section-background"></div>
  </div>
  <div class='content-wrapper'
    style='padding-top: calc(15vmax / 10); padding-bottom: calc(15vmax / 10);'>
    <div class="content">
      <div>
        <div class="fluid-engine fe-63891fe3412fdb85ddc7ba2f">
          <div class="fe-block fe-block-63891fe323333132d679f9a6">
            <div class="sqs-block html-block sqs-block-html">
              <div class="sqs-block-content">
                <div class="sqs-html-content">
                  <h3 style="white-space:pre-wrap;">{{lang?footer.company_name.content:footer.company_name.contentEn}}</h3><p class="" style="white-space:pre-wrap;"><a><span style="text-decoration:underline"><strong>{{lang?footer.company.content:footer.company.contentEn}}</strong></span></a></p><p class="" style="white-space:pre-wrap;"><a><span style="text-decoration:underline"><strong>{{lang?footer.company_email.content:footer.company_email.contentEn}}</strong></span></a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="fe-block fe-block-yui_3_17_2_1_1710966149877_4378">
            <div class="sqs-block html-block sqs-block-html">
              <div class="sqs-block-content">
                <div class="sqs-html-content">
                  <p class="sqsrte-small" style="white-space:pre-wrap;"><a href="https://beian.miit.gov.cn" target="_blank">{{lang?footer.company_filing.content:footer.company_filing.contentEn}}</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="fe-block fe-block-63891fe353e0ac3e37336096">
            <div class="sqs-block html-block sqs-block-html">
              <div class="sqs-block-content">
                <div class="sqs-html-content" v-if="footer.company_address">
                  <h3 style="white-space:pre-wrap;">{{lang?'公司地址' : 'Location'}}</h3><p class="" style="white-space:pre-wrap;"><a><span style="text-decoration:underline"><strong>{{lang?footer.company_address.content:footer.company_address.contentEn}}</strong></span></a></p><p class="" style="white-space:pre-wrap;"><a><span style="text-decoration:underline"><strong>{{lang?footer.company_address2.content:footer.company_address2.contentEn}}</strong></span></a></p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="fe-block fe-block-yui_3_17_2_1_1674502553730_3425">
            <div class="sqs-block button-block sqs-block-button sqs-stretched">
              <div class="sqs-block-content">
                <div class="sqs-block-button-container sqs-block-button-container--center">
                  <a href="/about"
                    class="sqs-block-button-element--large sqs-button-element--secondary sqs-block-button-element">
                    About
                  </a>
                </div>
              </div>
            </div>
          </div> -->
          <div class="fe-block fe-block-yui_3_17_2_1_1674502553730_6629">
            <div class="sqs-block button-block sqs-block-button sqs-stretched">
              <div class="sqs-block-content">
                <div class="sqs-block-button-container sqs-block-button-container--center">
                    <a
                      href="/news"
                      class="sqs-block-button-element--large sqs-button-element--secondary sqs-block-button-element">
                      {{lang?'新闻':'news'}}
                    </a>
                </div>
              </div>
            </div>
          </div>
          <div class="fe-block fe-block-c4d756708f819deb9d15">
            <div class="sqs-block button-block sqs-block-button sqs-stretched">
              <div class="sqs-block-content">
                <div class="sqs-block-button-container sqs-block-button-container--center">
                  <a href="/about"
                    class="sqs-block-button-element--large sqs-button-element--secondary sqs-block-button-element">
                    {{lang?'关于我们':'about'}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</footer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as Api from '@/api/api'
import config from "@/api/config"

export default {
  data(){
    return {
       tabber:[],
       logoImg:"",
       footer:{},
       showAll:false,
      //  activeName:"",
    }
  },
  mounted(){
     this.getInfo();
  },
  computed: {
    lang() {
      return this.$store.getters.lang == '中文'
    },
    activeName(){
      return this.$route.path
    },
  },
  methods:{
     
     async getInfo(){
        this.tabber = await Api.getList('顶部导航');
        this.footer = await Api.getInfo('底部配置');
        var logoInfo = await Api.getInfo('网站logo');
        this.logoImg = this.$getPath(logoInfo.logo_pic.content)
        this.showAll = true;
     },
    //  changeLang(){
    //     if(this.$store.getters.lang == 'English'){
    //        this.$store.dispatch('app/setLang', '中文')
    //     }else{
    //       this.$store.dispatch('app/setLang', 'English')
    //     }
    //  },
  }
}
</script>
<style scoped>

</style>
