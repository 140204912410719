const state = {
    lang: '中文',
  };
  
  const mutations = {
    SET_Lang: (state, lang) => {
      state.lang = lang;
      localStorage.setItem("lang", lang);
    }
  };
  
  const actions = {
    setLang({ commit }, lang) {
      commit("SET_Lang", lang);
    }
  };

  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };