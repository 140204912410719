import request from './index'


export const getInfo = async (category) => {
    return await request.get({ url: `/app/cyj/config/list?category=`+category})
}


export const getList = async (category) => {
    return await request.get({ url: `/app/cyj/list/list?category=`+category})
}

export const newList = async () => {
    return await request.get({ url: `/app/cyj/news/list`})
}


export const newDetail = async (id) => {
    return await request.get({ url: `/app/cyj/news/detail/`+id})
}