import axios from 'axios'

import { config } from '@/api/config'

const { base_url, request_timeout } = config

// 创建axios实例
const service = axios.create({
    baseURL: base_url, 
    timeout: request_timeout,
})
  
// request拦截器
service.interceptors.request.use()

// response 拦截器
service.interceptors.response.use(
    async (response) => {
    const { data } = response

    // 未设置状态码则默认成功状态
    const code = data.code

    return data
  })

export { service }
  