import Vue from 'vue'
import VueRouter from 'vue-router'
import Integration from '../views/Integration.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Integration',
    component: Integration
  },
  {
    path: '/home',
    name: 'home',
    component: function () {
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/SecondHomePage',
    name: 'SecondHomePage',
    component: function () {
      return import('../views/SecondHomePage.vue')
    }
  },
  {
    path: '/ThirdHomepage',
    name: 'ThirdHomepage',
    component: function () {
      return import('../views/ThirdHomepage.vue')
    }
  },
  {
    path: '/FourthHomePage',
    name: 'FourthHomePage',
    component: function () {
      return import('../views/FourthHomePage.vue')
    }
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/about.vue')
    }
  },
  {
    path: '/news',
    name: 'news',
    component: function () {
      return import('../views/news.vue')
    }
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: function () {
      return import('../views/newDetail.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
